define("spotassist/components/nav-bar", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    rootURL: _environment.default.rootURL,
    actions: {
      closeNavAction: function closeNavAction() {
        //this.sendAction('closeNavAction');
        document.getElementById("mySidenav").style.width = "0";
        document.body.style.backgroundColor = "white";
      },
      toggleSatteliteMode: function toggleSatteliteMode() {
        this.get('user-settings').set('satteliteMode', !this.get('user-settings').get('satteliteMode'));
        this.send('closeNavAction');
      },
      toggleCrowdDropzones: function toggleCrowdDropzones() {
        this.get('user-settings').set('crowdDropzonesOn', !this.get('user-settings').get('crowdDropzonesOn'));
        this.send('closeNavAction');
      },
      toggleRegisteredDropzones: function toggleRegisteredDropzones() {
        this.get('user-settings').set('registeredDropzonesOn', !this.get('user-settings').get('registeredDropzonesOn'));
        this.send('closeNavAction');
      },
      toggleRaobRadiusWeatherStations: function toggleRaobRadiusWeatherStations() {
        this.get('user-settings').set('raobRadiusWeatherStationsOn', !this.get('user-settings').get('raobRadiusWeatherStationsOn'));
        this.send('closeNavAction');
      },
      toggleOpenweatherRadiusWeatherStations: function toggleOpenweatherRadiusWeatherStations() {
        this.get('user-settings').set('openweatherRadiusWeatherStationsOn', !this.get('user-settings').get('openweatherRadiusWeatherStationsOn'));
        this.send('closeNavAction');
      },
      toggleMetarRadiusWeatherStations: function toggleMetarRadiusWeatherStations() {
        this.get('user-settings').set('metarRadiusWeatherStationsOn', !this.get('user-settings').get('metarRadiusWeatherStationsOn'));
        this.send('closeNavAction');
      },
      toggleTafRadiusWeatherStations: function toggleTafRadiusWeatherStations() {
        this.get('user-settings').set('tafRadiusWeatherStationsOn', !this.get('user-settings').get('tafRadiusWeatherStationsOn'));
        this.send('closeNavAction');
      }
    }
  });
});